import QRCode from 'react-qr-code';


export default function EventTicketLinkQR({link}) {
    return (
        <div className="eventTicketQR">
            <QRCode
                size={256}
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                value={link}
                viewBox={`0 0 256 256`}
            />
        </div>
    );
}
